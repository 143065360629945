import React from 'react';
import { Icon } from 'react-icons-kit';
import {whatsapp} from 'react-icons-kit/fa/whatsapp'
import './WhatsApp.scss'

export default function WhatsApp() {
    return (
        <div >
            <a href="https://api.whatsapp.com/send?phone=573213806861" class="btn-wsp" target="_blank"  rel="noreferrer">
            <Icon className="btn-wsp"  size={44} icon={whatsapp} />
	            </a>
        </div>
    )
}
